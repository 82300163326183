import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import StepContextProvider from './context/step-context';

import Layout from './layout/layout';
import Home from './pages/home';
import PrivacyPolicy from './pages/privacy-policy';
import CookiesPolicy from './pages/cookies-policy';
import Terms from './pages/terms';
import Licenses from './pages/licenses';
import NotFound from './pages/noFound';

const routes = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            { index: true, element: <Home /> },
            { path: 'privacy-policy', element: <PrivacyPolicy /> },
            { path: 'cookies-policy', element: <CookiesPolicy /> },
            { path: 'terms', element: <Terms /> },
            { path: 'accessibility-statement', element: <Licenses /> },
        ],
    },
    { path: '*', element: <NotFound /> },
]);

function App() {
    return (
        <StepContextProvider>
            <RouterProvider router={routes} />
        </StepContextProvider>
    );
}

export default App;
