import TermsUse from './../components/terms-use';

const Terms = () => {
    return (
        <>
            <TermsUse />
        </>
    );
};

export default Terms;
