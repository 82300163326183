import { Link } from 'react-router-dom';
import classes from './footer.module.css';

const Footer = () => {
    return (
        <footer className={classes.footer}>
            <div className='container'>
                <div className={classes['footer-line']}></div>

                <div className={classes['footer-content']}>
                    <ul className={classes['footer__list']}>
                        <li>
                            <Link to='/privacy-policy'>Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to='/terms'>Terms of Service</Link>
                        </li>
                        <li>
                            <Link to='/accessibility-statement'>Accessibility Statement</Link>
                        </li>
                    </ul>

                    <p>
                        By initiating a call to the insurance broker through our website, you
                        acknowledge and agree that you have reviewed and agreed to the various
                        disclosures and policies outlined on our website. Additionally, you
                        authorize us to connect your call to one of the insurance brokers in our
                        network for the purpose of assisting you with your insurance needs. Please
                        note that standard cellular charges may apply for the call.
                    </p>
                    <p></p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
