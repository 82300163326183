import LicensesDisclosure from '../components/licenses-disclosure';

const Licenses = () => {
    return (
        <>
            <LicensesDisclosure />
        </>
    );
};

export default Licenses;
