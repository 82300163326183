import Cookies from '../components/cookies-policy';

const CookiesPolicy = () => {
    return (
        <>
            <Cookies />
        </>
    );
};

export default CookiesPolicy;
