import Privacy from '../components/privacy-policy';

const PrivacyPolicy = () => {
    return (
        <>
            <Privacy />
        </>
    );
};

export default PrivacyPolicy;
