import classes from './terms-use.module.css';

const TermsUse = () => {
    return (
        <section className={classes.terms}>
            <div className='container'>
                <h2>Terms of Service:</h2>

                <p>
                    By accessing and using insurancebenefits.org, you agree to comply with these
                    Terms of Service. Please read these Terms of Service carefully before using our
                    website.
                </p>

                <p>
                    You are granted a limited, non-exclusive, and revocable license to access and
                    use the content on insurancebenefits.org for personal, non-commercial purposes.
                    You may not reproduce, distribute, modify, or otherwise exploit any content
                    without our prior written consent.
                </p>
                <p>
                    The content provided on insurancebenefits.org is for informational purposes only
                    and should not be considered professional advice or a substitute for
                    professional guidance. We make no warranties or representations regarding the
                    accuracy, completeness, or reliability of the content.
                </p>
                <p>
                    As a for-profit performance marketing company, we facilitate customer-initiated
                    calls to various insurance brokers, including Synergy, Enhance, TrueCoverage,
                    and Coverall. We do not collect customer names or personal information during
                    this process. When you initiate a call to an insurance broker through our
                    website, you will be providing your personal information directly to the
                    insurance broker. Any communication or transaction between you and an insurance
                    broker is solely between you and the insurance broker.
                </p>
                <p>
                    You are responsible for your use of insurancebenefits.org and agree not to
                    engage in unlawful activities or unauthorized use of the website. We reserve the
                    right to suspend or terminate your access to the website at any time without
                    notice.
                </p>
                <p>
                    Insurancebenefits.org may contain links to third-party websites or resources. We
                    are not responsible for the availability or accuracy of such external sites or
                    resources and do not endorse or warrant the content, products, or services
                    provided.
                </p>
                <p>
                    These Terms of Service shall be governed by and construed in accordance with the
                    laws of the State of Arizona, without regard to its conflict of law provisions.
                    Any dispute arising out of or relating to these Terms of Service or your use of
                    insurancebenefits.org shall be subject to the exclusive jurisdiction of the
                    state and federal courts located in the State of Arizona.
                </p>

                <p>
                    If you have any questions or concerns regarding these Terms of Service, please
                    contact us at info@insurancebenefits.org.
                </p>
            </div>
        </section>
    );
};

export default TermsUse;
