import classes from './cookies-policy.module.css';

const CookiesPolicy = () => {
    return (
        <section className={classes.cooky}>
            <div className='container'>
                <h2>Cookies Policy:</h2>
                <p>
                    Our website uses cookies to enhance user experience and collect information
                    about how our website is accessed and used. By using our website, you consent to
                    the use of cookies in accordance with this policy.
                </p>

                <h5>What are cookies?</h5>
                <p>
                    Cookies are small files that are placed on your device (computer, tablet, or
                    mobile device) when you visit a website. They serve various purposes, including
                    providing a personalized browsing experience, remembering user preferences, and
                    analyzing website traffic.
                </p>

                <h5>Types of cookies we use:</h5>

                <div className={classes['cooky-types']}>
                    <p>
                        - Essential Cookies: These cookies are necessary for the functioning of our
                        website and cannot be disabled. They enable core functionality, such as
                        allowing users to navigate the website and access secure areas.
                    </p>

                    <p>
                        - Analytical/Performance Cookies: These cookies collect information about
                        how visitors use our website. They help us understand how our website is
                        performing, identify areas for improvement, and optimize the user
                        experience.
                    </p>
                    <p>
                        - Functionality Cookies: These cookies enable our website to remember user
                        preferences and provide enhanced features and personalization.
                    </p>
                    <p>
                        - Advertising/Marketing Cookies: These cookies are used to deliver relevant
                        advertisements and marketing messages to users based on their interests and
                        browsing behavior.
                    </p>
                </div>

                <h5>Managing cookies:</h5>
                <p>
                    You can manage your cookie preferences through the settings of your web browser.
                    You can choose to accept or decline cookies, delete cookies already stored on
                    your device, or set your browser to notify you when cookies are being used.
                    Please note that disabling certain cookies may impact the functionality and user
                    experience of our website.
                </p>

                <h5>Copyright Notice:</h5>
                <p>
                    All content on this website, including text, graphics, logos, images, videos,
                    and audio, is the property of Insurance Marketing Group LLC, unless otherwise
                    stated. The content is protected by copyright laws and international treaties.
                </p>
                <p>
                    Unauthorized use, reproduction, distribution, or modification of any content on
                    this website is strictly prohibited without prior written permission from
                    Insurance Marketing Group LLC.
                </p>
                <p>
                    Please review and customize these policies further to align them with your
                    specific business practices, website requirements, and applicable laws.
                </p>
            </div>
        </section>
    );
};

export default CookiesPolicy;
