import { Link } from 'react-router-dom';
import classes from './privacy-policy.module.css';

const Privacy = () => {
    return (
        <section className={classes.privacy}>
            <div className='container'>
                <h2>Privacy Policy:</h2>

                <p>
                    Insurance Marketing Group LLC, doing business as insurancebenefits.org, is
                    committed to protecting your privacy. This Privacy Policy explains how we
                    collect, use, and safeguard your personal information. Please read this Privacy
                    Policy carefully. By accessing or using our website insurancebenefits.org, you
                    signify your acceptance of this Privacy Policy. If you do not agree with the
                    practices described in this Privacy Policy, please do not use our website.
                </p>

                <p>
                    As a for-profit performance marketing company, we facilitate customer-initiated
                    calls to various insurance brokers, including Synergy, Enhance, TrueCoverage,
                    and Coverall. We are not affiliated with any government agency or insurance
                    carrier. We do not collect customer names or personal information during this
                    process. When you use our website, you may be redirected to insurance broker
                    websites or other third-party platforms where you may provide your personal
                    information directly to those entities. Please review the privacy policies of
                    those websites or platforms to understand how your personal information will be
                    collected, used, and safeguarded.
                </p>

                <p>
                    We may use cookies and other tracking technologies on our website to enhance
                    user experience and collect information about how our website is accessed and
                    used. However, we do not link this information to any personally identifiable
                    information.
                </p>

                <p>
                    Please note that when you initiate a call to an insurance broker through our
                    website, you will be providing your personal information directly to the
                    insurance broker. We are not responsible for the privacy practices or the
                    content of insurance broker websites or platforms.
                </p>

                <p>
                    Please remember that insurance brokers may be subject to the regulations of the
                    Telephone Consumer Protection Act (TCPA) and other applicable laws governing
                    telemarketing and solicitation calls. By initiating a call to an insurance
                    broker through our website, you acknowledge and agree that the insurance broker
                    may contact you in compliance with these regulations for the purposes of
                    fulfilling your request.
                </p>

                <p>
                    We want to clarify that while we strive to connect you with insurance brokers
                    who may assist you in finding suitable insurance benefits, we cannot guarantee
                    that any user will qualify for coverage or what their insurance premiums, tax
                    credits, or potential reimbursements may be. The assessment of eligibility for
                    insurance benefits, premiums, tax credits, and potential reimbursements will be
                    determined by the insurance broker based on their evaluation of your specific
                    circumstances. Please note that the few high-level screening questions we ask
                    during the process do not guarantee eligibility for insurance benefits. These
                    questions are designed to screen at a high level based on basic requirements for
                    insurance coverage under the "Affordable Care Act" program.
                </p>

                <p>
                    To view our cookies and copyright policy, click{' '}
                    <Link to='/cookies-policy'>here</Link>
                </p>
            </div>
        </section>
    );
};

export default Privacy;
