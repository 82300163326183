import classes from './licenses-disclosure.module.css';

const LicensesDisclosure = () => {
    return (
        <section className={classes.licenses}>
            <div className='container'>
                <h2>Accessibility Statement:</h2>

                <p>
                    Insurance Marketing Group LLC is committed to ensuring digital accessibility for
                    individuals with disabilities. We strive to provide a website that is accessible
                    to all users, regardless of their abilities.
                </p>
                <p>
                    We are continuously working to improve the accessibility of our website and aim
                    to adhere to the Web Content Accessibility Guidelines (WCAG) 2.1 Level AA
                    standards.
                </p>
                <p>
                    If you have any accessibility-related concerns or questions or require
                    assistance in accessing the content on our website, please contact us at
                    info@insurancebenefits.org. We will make reasonable efforts to address your
                    concerns and provide you with the information you need.
                </p>
            </div>
        </section>
    );
};

export default LicensesDisclosure;
