import Error from '../components/error/error';

const NotFound = () => {
    return (
        <>
            <Error />
        </>
    );
};

export default NotFound;
